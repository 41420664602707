import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ViewTravelItemComponent } from './view-travel-item/view-travel-item.component';
import { TravelDashboardComponent } from './travel-dashboard/travel-dashboard.component';
import { TravelComponent } from './travel.component';

const routes: Routes = [
  {
    path: '',
    component: TravelComponent,
    children: [
        {
            path: '',
            component: TravelDashboardComponent,
        },
        {
            path: ':country',
            component: ViewTravelItemComponent,
        },
    ],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TravelRoutingModule { }
