import { Component, OnInit, Input } from '@angular/core';
import { TravelItem } from 'src/app/models/travel-item';
import { Router } from '@angular/router';

@Component({
  selector: 'app-travel-card',
  templateUrl: './travel-card.component.html',
  styleUrls: ['./travel-card.component.scss']
})
export class TravelCardComponent implements OnInit {

  @Input() travelItem: TravelItem = null;

  constructor(
    private _router: Router,
  ) { }

  ngOnInit() {
  }

  navigateToTravelItem(): void {
    this._router.navigate(['/travel', this.travelItem.name], {
      state: {
        data: {
          travelInfo: this.travelItem,
        },
      },
    });
  }

}
