import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs/Observable';
import { environment } from 'src/environments/environment';
import { TravelItem } from '../models/travel-item';

@Injectable({
  providedIn: 'root'
})
export class WebsiteService {

  constructor(
    private _api: ApiService,
  ) { }

  getCountry(countryName: string): Observable<object> {
    const url = environment.GET_COUNTRY_URL.replace(':COUNTRY', countryName);
    return new Observable<object>(subscriber => {
      this._api.get<object>(url).subscribe(
        response => {
          subscriber.next(response);
        },
        error => {
          subscriber.next(error);
        });
      });
  }

  getCountryPhotos(countryName: string): Observable<object> {
    const url = environment.GET_COUNTRY_PHOTOS_URL.replace(':COUNTRY', countryName);
    return new Observable<object>(subscriber => {
      this._api.get<object>(url).subscribe(
        response => {
          subscriber.next(response);
        },
        error => {
          subscriber.next(error);
        });
      });
  }

  getAllCountries(): Observable<TravelItem[]> {
    const url = environment.GET_ALL_COUNTRIES_URL;
    return new Observable<TravelItem[]>(subscriber => {
      this._api.get<TravelItem[]>(url).subscribe(
        response => {
          subscriber.next(response['countries']);
        },
        error => {
          subscriber.next(error);
        });
      });
  }

  getPortfolio(): Observable<PortfolioItem[]> {
    const url = environment.GET_PORTFOLIO_URL;
    return new Observable<PortfolioItem[]>(subscriber => {
      this._api.get<PortfolioItem[]>(url).subscribe(
        response => {
          subscriber.next(response['portfolio']);
        },
        error => {
          subscriber.next(error);
        });
      });
  }

  getNews(): Observable<News[]> {
    const url = environment.GET_NEWS_URL;
    return new Observable<News[]>(subscriber => {
      this._api.get<News[]>(url).subscribe(
        response => {
          subscriber.next(response['news']);
        },
        error => {
          subscriber.next(error);
        });
      });
  }
}
