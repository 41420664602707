import { Component, OnInit, ChangeDetectorRef, HostListener } from '@angular/core';
import { trigger, transition, animate, style } from '@angular/animations';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger(
      'enterAnimation', [
        transition(':enter', [
          style({opacity: 0}),
          animate('700ms', style({opacity: 1}))
        ]
      )
    ])
  ]
})
export class AppComponent implements OnInit {
  opened = true;
  bigScreen = true;
  mode = 'side';

  constructor(
    private _changeDetection: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.onResize(window);
  }

  toogleSideNav(): void {
    this.opened = !this.opened;
  }

  closeMenu(): void {
    this.opened = false;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    let innerWidth = 0;
    if (event.innerWidth) {
      innerWidth = event.innerWidth;
    } else {
      innerWidth = event.target.innerWidth;
    }
    if (innerWidth < 500) {
      this.bigScreen = false;
      this.opened = false;
      this.mode = 'over';
    } else {
      this.bigScreen = true;
      this.opened = true;
      this.mode = 'side';
    }
  }
}
