import { Component, OnInit } from '@angular/core';
import { TravelItem } from 'src/app/models/travel-item';
import { WebsiteService } from 'src/app/services/website.service';

@Component({
  selector: 'app-travel-dashboard',
  templateUrl: './travel-dashboard.component.html',
  styleUrls: ['./travel-dashboard.component.scss']
})
export class TravelDashboardComponent implements OnInit {
  travelList: TravelItem[] = [];

  errorMessage = '';

  constructor(
    private _websiteService: WebsiteService,
  ) { }

  ngOnInit() {
    this._websiteService.getAllCountries().subscribe(response => {
      if (response['error']) {
        this.errorMessage = response['error'];
      } else {
        this.errorMessage = '';
        this.travelList = response;
      }
    });
  }

}
