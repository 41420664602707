import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PortfolioComponent } from './portfolio.component';
import { PortfolioListComponent } from './portfolio-list/portfolio-list.component';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { MatCardModule } from '@angular/material';
import { PortfolioRoutingModule } from './portfolio-routing.module';

@NgModule({
  declarations: [
    PortfolioComponent,
    PortfolioListComponent,
  ],
  imports: [
    CommonModule,
    AngularFontAwesomeModule,
    MatCardModule,
    PortfolioRoutingModule,
  ]
})
export class PortfolioModule { }
