import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResumeComponent } from './resume.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { MatCardModule } from '@angular/material';
import { ResumeRoutingModule } from './resume-routing.module';

@NgModule({
  declarations: [
    ResumeComponent,
  ],
  imports: [
    CommonModule,
    PdfViewerModule,
    MatCardModule,
    ResumeRoutingModule,
  ]
})
export class ResumeModule { }
