import { Component, OnInit } from '@angular/core';
import { WebsiteService } from 'src/app/services/website.service';

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.scss']
})
export class PortfolioComponent implements OnInit {
  portfolioList: PortfolioItem[] = [];

  errorMessage = '';

  constructor(
    private _websiteService: WebsiteService,
  ) { }

  ngOnInit() {
    this._websiteService.getPortfolio().subscribe(response => {
      if (response['error']) {
        this.errorMessage = response['error'];
      } else {
        this.portfolioList = response;
      }
    });
  }

}
