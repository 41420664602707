import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { TravelItem } from 'src/app/models/travel-item';
import { ActivatedRoute } from '@angular/router';
import { WebsiteService } from 'src/app/services/website.service';

@Component({
  selector: 'app-view-travel-item',
  templateUrl: './view-travel-item.component.html',
  styleUrls: ['./view-travel-item.component.scss']
})
export class ViewTravelItemComponent implements OnInit {
  travelItem: TravelItem = null;
  imageURLs: string[] = [];

  cached = false;
  errorMessage = '';

  constructor(
    private _route: ActivatedRoute,
    private _websiteService: WebsiteService,
    private _changeDetection: ChangeDetectorRef,
  ) {
  }

  ngOnInit() {
    const countryName = this._route.snapshot.params['country'];
    if (history.state.data !== undefined) {
      this.cached = true;
      this.travelItem = history.state.data.travelInfo;
      this._getCountryPhotos(countryName);
    } else {
      this._getCountry(countryName);
    }
  }

  private _getCountry(countryName: string): void {
    this._websiteService.getCountry(countryName).subscribe(response => {
      if (response['error']) {
        this.errorMessage = 'An error occured or you may be on a wrong page. Go back to home here!';
      } else {
        this.imageURLs = response['imageUrls'];
        this.travelItem = response['travelInfo'];
      }
      setTimeout(() => {
        this._changeDetection.detectChanges();
      }, 100);
    });
  }

  private _getCountryPhotos(countryName: string): void {
    this._websiteService.getCountryPhotos(countryName).subscribe(response => {
      if (response['error']) {
        this.errorMessage = 'An error occured or you may be on a wrong page. Go back to home here!';
      } else {
        this.imageURLs = response['imageUrls'];
      }
      setTimeout(() => {
        this._changeDetection.detectChanges();
      }, 100);
    });
  }
}
