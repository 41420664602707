import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Router } from '@angular/router';

interface MenuLink {
  icon: string;
  title: string;
  ariaLabel: string;
  link: { [k: string]: any };
  external: boolean;
}

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {
  currentTab = '';
  menuLinks: MenuLink[] = [
    {
      icon: 'home',
      title: 'Home',
      ariaLabel: 'Go to home page',
      link: ['/home'],
      external: false,
    },
    {
      icon: 'rss-square',
      title: 'News',
      ariaLabel: 'Go to recent news',
      link: ['/news'],
      external: false,
    },
    {
      icon: 'male',
      title: 'Resume',
      ariaLabel: 'Go to my resume',
      link: ['/resume'],
      external: false,
    },
    {
      icon: 'laptop',
      title: 'Portfolio',
      ariaLabel: 'Go to my portfolio',
      link: ['/portfolio'],
      external: false,
    },
    {
      icon: 'paper-plane',
      title: 'Travel',
      ariaLabel: 'Go to all of my travels',
      link: ['/travel'],
      external: false,
    },
    {
      icon: 'envelope',
      title: 'Contact',
      ariaLabel: 'Contact me by email',
      link: ['mailto:chrischomicki@gmail.com'],
      external: true,
    }
  ];

  @Input() bigScreen = false;
  @Output() toggle: EventEmitter<any> = new EventEmitter();

  constructor(
    private _router: Router,
  ) { }

  ngOnInit() {
    this.menuLinks.forEach(menuLink => {
      if (this._router.url.includes(menuLink.link[0])) {
        this.currentTab = menuLink.title;
      }
    });
  }

  onClick(event) {
    this.currentTab = event.target.innerText.trim();
    if (!this.bigScreen) {
      this.toggle.emit(null);
    }
  }

}
