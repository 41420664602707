import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TravelComponent } from './travel.component';
import { TravelCardComponent } from './travel-card/travel-card.component';
import { MatGridListModule, MatCardModule, MatProgressSpinnerModule } from '@angular/material';
import { ViewTravelItemComponent } from './view-travel-item/view-travel-item.component';
import { TravelRoutingModule } from './travel-routing.module';
import { TravelDashboardComponent } from './travel-dashboard/travel-dashboard.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  declarations: [
    TravelComponent,
    TravelCardComponent,
    ViewTravelItemComponent,
    TravelDashboardComponent,
  ],
  imports: [
    CommonModule,
    MatGridListModule,
    MatCardModule,
    TravelRoutingModule,
    MatProgressSpinnerModule,
  ]
})
export class TravelModule { }
