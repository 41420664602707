import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  authToken: string;
  csrfToken: string;

  constructor(
    private _http: HttpClient,
  ) { }

  private get httpOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
      })
    };
  }

  public headers() {
    return {
      'Content-Type':  'application/json',
    };
  }

  public get<T>(url): Observable<T> {
    return this._http.get<T>(url, this.httpOptions);
  }

  public post<T, U>(url, data: U): Observable<T> {
    return this._http.post<T>(url, data, this.httpOptions);
  }


  public put<T, U>(url, data: U): Observable<T> {
    return this._http.put<T>(url, data, this.httpOptions);
  }

  public delete<T>(url): Observable<T> {
    return this._http.delete<T>(url, this.httpOptions);
  }

}
