// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  BASE_URL: 'https://kwjj1qoywk.execute-api.us-west-2.amazonaws.com/default/',

  GET_COUNTRY_URL: 'https://kwjj1qoywk.execute-api.us-west-2.amazonaws.com/default/getCountry/:COUNTRY/',

  GET_COUNTRY_PHOTOS_URL: 'https://kwjj1qoywk.execute-api.us-west-2.amazonaws.com/default/getCountry/photos/:COUNTRY/',

  GET_ALL_COUNTRIES_URL: 'https://kwjj1qoywk.execute-api.us-west-2.amazonaws.com/default/getAllCountries/',

  GET_PORTFOLIO_URL: 'https://kwjj1qoywk.execute-api.us-west-2.amazonaws.com/default/getPortfolio/',

  GET_NEWS_URL: 'https://kwjj1qoywk.execute-api.us-west-2.amazonaws.com/default/getNews/',

  RESUME_URL: 'https://personalwebsite-images.s3.amazonaws.com/chomicki_chris_resume.pdf',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
